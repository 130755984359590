<template>
    <div>
        <div class="workspace-task">
            <div :class="['bar-top', {unsaved}]" />

            <div class="workspace-task-header">
                <div class="workspace-task-title">
                    Task {{ step }}: {{ title }}
                </div>

                <div
                    :id="`step${step}InfoButton`"
                    class="workspace-task-actions"
                >
                    <FontAwesomeIcon
                        v-b-toggle="`descriptionCollapse${step}`"
                        icon="info-circle"
                        size="lg"
                        class="fa imt-ui-help-wording action info mr-2"
                    />
                </div>

                <div
                    v-if="!readOnly"
                    :id="`step${step}OptionsButton`"
                    :data-cy="`deleteTask${step}`"
                    class="workspace-task-actions"
                >
                    <FontAwesomeIcon
                        class="action delete"
                        icon="trash-alt"
                        size="lg"
                    />

                    <BTooltip
                        placement="left"
                        :target="`step${step}OptionsButton`"
                        :title="`Delete Task ${step}`"
                        triggers="hover"
                    />

                    <KCPopConfirm
                        :target="`step${step}OptionsButton`"
                        placement="left"
                        @confirmed="$emit('deleted', task)"
                    />
                </div>
            </div>

            <BCollapse
                :id="`descriptionCollapse${step}`"
                class="workspace-task-description px-4"
                visible
            >
                <p>
                    {{ getDescription }}
                </p>
            </BCollapse>

            <div class="workspace-task-content">
                <div class="row w-100">
                    <div class="col-12 col-lg-4">
                        <h5>
                            Options
                        </h5>

                        <BFormGroup data-cy="runsParallel">
                            <BCheckbox
                                name="parallel"
                                switch
                                :disabled="readOnly"
                                :checked="task.allowParallel"
                                @change="updateTask({key: 'allowParallel', value: $event})"
                            >
                                Runs in parallel
                                <span :id="`parallelInfo${step}`">
                                    <FontAwesomeIcon
                                        icon="info-circle"
                                        class="fa kc-help-wording"
                                    />
                                </span>

                                <BTooltip
                                    custom-class="kc-help-wording"
                                    :target="`parallelInfo${step}`"
                                    placement="right"
                                >
                                    This allows the next task to start without waiting for this one to return.
                                </BTooltip>
                            </BCheckbox>
                        </BFormGroup>

                        <BFormGroup data-cy="requiresCallback">
                            <BCheckbox
                                name="callback"
                                switch
                                :disabled="readOnly"
                                :checked="task.hasCallback"
                                @change="updateTask({key: 'hasCallback', value: $event})"
                            >
                                Requires a callback to continue
                                <span :id="`callbackInfo${step}`">
                                    <FontAwesomeIcon
                                        icon="info-circle"
                                        class="fa kc-help-wording"
                                    />
                                </span>

                                <BTooltip
                                    :target="`callbackInfo${step}`"
                                    placement="right"
                                >
                                    This signifies that this task expects a callback from an external service before continuing the process can occur.
                                </BTooltip>
                            </BCheckbox>
                        </BFormGroup>
                    </div>

                    <div class="col-12 col-lg-8 mb-2">
                        <h5 class="d-flex flex-row align-items-center w-100">
                            Task Variables
                            <span
                                :id="`variablesInfo${step}`"
                                class="ml-1"
                            >
                                <FontAwesomeIcon
                                    icon="info-circle"
                                    class="fa kc-help-wording"
                                    size="sm"
                                />
                            </span>

                            <BTooltip
                                :target="`variablesInfo${step}`"
                                placement="right"
                            >
                                Set some base variables and their values. This is useful to be able to pass in
                                <em>hard-coded</em>
                                values into task arguments. These variables will be combined with the variables so far in the process, with any values already existing in the job taking precedence.
                                <p class="m-0 mt-2">
                                    Hidden by default to save you scrolling, as the list of variables has no limit.
                                </p>
                            </BTooltip>

                            <BButton
                                variant="outline-primary"
                                size="sm"
                                class="ml-auto"
                                data-cy="showVariables"
                                @click="openVariables($event)"
                            >
                                {{ variablesVisible ? 'Hide' : 'Show' }} Variables

                                <FontAwesomeIcon
                                    icon="angle-double-down"
                                    :class="['fa', 'animated-transform', 'ml-2', 'mr-0', {'fa-flip-vertical': variablesVisible}]"
                                />
                            </BButton>
                        </h5>

                        <BCollapse
                            :id="`configureVariablesForStep${step}`"
                            v-model="variablesVisible"
                        >
                            <BListGroup class="w-100 variables">
                                <template v-if="variables && Object.keys(variables).length">
                                    <BListGroupItem class="list-group-header d-flex flex-row">
                                        <div class="w-50">
                                            Name
                                        </div>
                                        <div class="w-50">
                                            Value
                                        </div>
                                    </BListGroupItem>

                                    <BListGroupItem
                                        v-for="(value, variable, vIndex) in variables"
                                        :key="variable"
                                        :class="['d-flex', 'flex-row', 'align-items-top', {'bg-alternate': vIndex % 2}]"
                                    >
                                        <span class="w-50 d-inline-flex align-items-start">
                                            <span
                                                class="mr-2"
                                                data-cy="deleteVariable"
                                            >
                                                <FontAwesomeIcon
                                                    v-if="!readOnly"
                                                    :id="`task${task.id}Variable${vIndex}Delete`"
                                                    icon="trash-alt"
                                                    class="action delete"
                                                />

                                                <BTooltip
                                                    :target="`task${task.id}Variable${vIndex}Delete`"
                                                    placement="left"
                                                    :title="`Delete variable ${variable}`"
                                                />

                                                <KCPopConfirm
                                                    :target="`task${task.id}Variable${vIndex}Delete`"
                                                    placement="bottom"
                                                    @confirmed="handleDeleteVariable(variable, `task${task.id}Variable${vIndex}Delete`)"
                                                />
                                            </span>
                                            {{ variable }}
                                        </span>

                                        <BFormGroup class="w-50 my-1">
                                            <BTextarea
                                                :value="value"
                                                class="text-formatted"
                                                placeholder="enter a value..."
                                                :disabled="readOnly"
                                                data-cy="variableValue"
                                                @input="handleSaveVariable(variable, $event)"
                                            />
                                        </BFormGroup>
                                    </BListGroupItem>
                                </template>
                                <BListGroupItem
                                    v-else
                                    class="text-emphasize py-4"
                                >
                                    No variables defined yet for task.
                                </BListGroupItem>

                                <BListGroupItem v-if="!readOnly">
                                    <BFormGroup
                                        label="Add a new variable:"
                                        :state="newVariableErrors.length === 0"
                                        :invalid-feedback="newVariableErrors[0]"
                                        class="m-0"
                                    >
                                        <BInputGroup>
                                            <BFormInput
                                                v-model="newVariableName"
                                                :disabled="readOnly"
                                                data-cy="newVariableName"
                                                placeholder="enter a variable name..."
                                                @keydown.enter="handleAddVariable"
                                            />

                                            <template slot="append">
                                                <BButton
                                                    variant="success"
                                                    :disabled="readOnly"
                                                    data-cy="addVariable"
                                                    @click="handleAddVariable"
                                                >
                                                    <FontAwesomeIcon
                                                        class="mx-0"
                                                        icon="plus-circle"
                                                    />
                                                </BButton>
                                            </template>
                                        </BInputGroup>
                                    </BFormGroup>
                                </BListGroupItem>
                            </BListGroup>
                        </BCollapse>
                    </div>
                </div>

                <div class="row w-100">
                    <h5 class="col-12">
                        Arguments
                    </h5>

                    <div
                        v-if="!getArguments.length"
                        class="col-12 text-muted"
                        data-cy="noArgumentsMessage"
                    >
                        <em>Task has no arguments.</em>
                    </div>

                    <div
                        v-for="(argument, aIndex) in getArguments"
                        :id="`task${task.id}Argument${aIndex}`"
                        :key="argument"
                        class="col-12 row"
                    >
                        <h6 class="col-12">
                            {{ capitalWords(argument.replace(/_/g, ' ')) }}
                            <template v-if="getArgumentsHelpWording[argument]">
                                <FontAwesomeIcon
                                    :id="`argumentHelpWording${argument}`"
                                    icon="info-circle"
                                    class="fa kc-help-wording"
                                    data-cy="argumentHelpWordingIcon"
                                />

                                <BPopover
                                    custom-class="kc-help-wording"
                                    :target="`argumentHelpWording${argument}`"
                                    :title="capitalWords(argument.replace(/_/g, ' '))"
                                    triggers="hover click"
                                    data-cy="argumentHelpWordingPopover"
                                >
                                    {{ getArgumentsHelpWording[argument] }}
                                </BPopover>
                            </template>
                        </h6>

                        <BFormGroup
                            :data-cy="`argument${task.id}${aIndex}`"
                            class="col-12 col-lg-auto mb-0 mb-lg-2"
                            label="Where is the value coming from?"
                        >
                            <IMTUISelect
                                data-cy="IMTUISelectionBox"
                                :read-only="readOnly"
                                :options="[
                                    {
                                        label: 'Variables',
                                        value: 'variables',
                                    },
                                    {
                                        label: 'Specified Value',
                                        value: 'hardCoded',
                                    }
                                ]"
                                :value="task.functionArguments[argument].source"
                                @selection="updateTask({key: `functionArguments[${argument}].source`, value: $event})"
                            />
                        </BFormGroup>

                        <BFormGroup class="col-12 col-lg-4">
                            <template slot="label">
                                <template v-if="task.functionArguments[argument].source === 'hardCoded'">
                                    Value
                                </template>
                                <template v-else>
                                    Key Path
                                </template>
                            </template>

                            <BFormInput
                                type="text"
                                class="form-control"
                                :placeholder="`specify the ${task.functionArguments[argument].source === 'hardCoded' ? '' : 'key path to the' } value...`"
                                :disabled="readOnly"
                                :data-cy="`${task.functionArguments[argument].source}`"
                                :value="task.functionArguments[argument].keyPath"
                                @input="updateTask({key: `functionArguments[${argument}].keyPath`, value: $event})"
                            />
                        </BFormGroup>
                    </div>
                </div>

                <slot />
            </div>
        </div>

        <div class="tail" />
    </div>
</template>

<script>
    import IMTUISelect from '@imt/vue-imt-ui-select/src/components/IMTUISelect.vue';
    import {KCPopConfirm} from '@imt/vue-kit-car';
    import filterMixin from '@imt/vue-toolbox/src/mixins/filters';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import set from 'lodash/set';
    import {
        mapGetters,
        mapState,
    } from 'vuex';

    export default {
        name: 'BHWorkspaceTask',
        components: {
            IMTUISelect,
            KCPopConfirm,
        },
        mixins: [
            filterMixin,
            toastsMixin,
        ],
        props: {
            readOnly: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
            task: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                argumentSources: {},
                newVariableName: '',
                newVariableErrors: [],
                variablesVisible: false,
                unsavedChanges: false,
            };
        },
        computed: {
            variables() {
                return this.task.variables;
            },
            getArguments() {
                return this.actions.find(t => {
                    let comparison = this.task.function || this.task.title;

                    return t.title === comparison;
                })?.arguments;
            },
            getArgumentsHelpWording() {
                return this.actions.find(t => {
                    let comparison = this.task.function || this.task.title;

                    return t.title === comparison;
                })?.helpWording;
            },
            getDescription() {
                return this.actions.find(t => {
                    let comparison = this.task.function || this.task.title;

                    return t.title === comparison;
                })?.description;
            },
            unsaved() {
                return !this.task.id || this.unsavedChanges;
            },
            ...mapGetters('builder', [
                'actions',
            ]),
            ...mapState('builder', [
                'availableTasks',
                'version'
            ]),
        },
        watch: {
            task: {
                immediate: true,
                handler() {
                    this.setupArgs();
                }
            },
            availableTasks() {
                this.setupArgs();
            },
        },
        methods: {
            async handleAddVariable() {
                this.newVariableErrors.splice(0, 1);
                if (this.newVariableName.length && !Object.prototype.hasOwnProperty.call(this.task.variables, this.newVariableName)) {
                    await this.handleSaveVariable(this.newVariableName);
                } else if (Object.prototype.hasOwnProperty.call(this.task.variables, this.newVariableName)) {
                    this.newVariableErrors.push('Variable already defined.');
                } else if (!this.newVariableName.length) {
                    this.newVariableErrors.push('You must provide a variable name.');
                }

                this.newVariableName = '';
            },
            async handleDeleteVariable(variableName, popoverId) {
                this.$root.$emit('bv::hide::popover', popoverId);

                let variables = {...this.task.variables};
                delete variables[variableName];
                await this.updateTask({
                    key: 'variables',
                    value: variables,
                });
            },
            async handleSaveVariable(variableName, variableValue = '') {
                let variables = {...this.task.variables};
                variables[variableName] = variableValue;
                await this.updateTask({
                    key: 'variables',
                    value: variables,
                });
            },
            openVariables(event) {
                this.variablesVisible = !this.variablesVisible;
                event.target.blur();
            },
            updateTask({key, value}) {
                let task = {...this.task};
                set(task, key, value);
                this.unsavedChanges = true;
                this.$emit('updated', task);
            },
            setupArgs() {
                if (!this.task.functionArguments) {
                    this.version.tasks[this.step - 1].functionArguments = {};
                }

                if (this.task.functionArguments && this.getArguments && Object.keys(this.task.functionArguments).length !== this.getArguments.length) {
                    this.getArguments.forEach(arg => {
                        if (!Object.prototype.hasOwnProperty.call(this.task.functionArguments, arg)) {
                            this.version.tasks[this.step - 1].functionArguments[arg] = {
                                source: '',
                                keyPath: '',
                            };
                        }
                    });
                }
            },
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .workspace-task
        background-color: var(--kc-gray-100)
        border-radius: var(--card-border-radius)
        display: flex
        flex-direction: column

        .bar-top
            height: 0.25rem
            background: var(--kc-blue-500)
            width: 100%
            border-radius: var(--card-border-radius) var(--card-border-radius) 0 0
            margin: 0

            &.unsaved
                background: var(--kc-yellow-500) !important

        .workspace-task-header
            display: flex
            flex-direction: row
            font-size: 1.2rem
            padding: 0.75rem
            margin-bottom: 0.25rem

            .workspace-task-title
                flex-grow: 1

            .workspace-task-actions
                flex-grow: 0
                margin-left: auto
                align-self: center

        .action
            &:hover
                cursor: pointer

            &.delete
                color: var(--kc-red-500)

            &.info
                color: var(--kc-blue-400)

            &.options
                color: var(--kc-blue-500)

        .workspace-task-content
            display: flex
            flex-direction: column
            align-items: flex-start
            padding: 0 1rem 1rem 1rem

            .fa
                &.animated-transform
                    transition: transform 250ms

            .variables
                .list-group-item
                    background-color: var(--kc-gray-200)
                    border-color: var(--kc-gray-500)

                    &.list-group-header
                        font-weight: bold
                        background-color: var(--kc-gray-300)

    .bg-alternate
        background-color: var(--kc-gray-300) !important

    .tail
        border-left: 0.25rem dashed var(--kc-gray-200)
        height: 2rem
        width: 0
        margin: 0 auto

    .mode
        &.dark
            .custom-control-input:checked
                ~ .custom-control-label
                    &::before
                        background-color: var(--kc-blue-500)
                        border-color: var(--kc-blue-500)

            .workspace-task
                background-color: var(--kc-blue-900)

                .bar-top
                    background: var(--kc-blue-600)

                    &.unsaved
                        background: var(--warning) !important

                .action
                    color: var(--kc-blue-400)

                    &.delete
                        color: var(--kc-red-500)

                        &:hover
                            color: var(--kc-red-600)

                    &:hover
                        color: var(--kc-blue-600)
                        cursor: pointer

                .variables
                    .list-group-item
                        background-color: var(--kc-blue-700)
                        border-color: var(--kc-blue-900)

                        &.list-group-header
                            font-weight: bold
                            background-color: var(--kc-blue-800)

            .bg-alternate
                background-color: var(--kc-blue-800) !important

            .tail
                border-left-color: var(--kc-blue-900)
</style>

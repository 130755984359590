import { render, staticRenderFns } from "./BHToolboxPanel.vue?vue&type=template&id=07b39221&scoped=true&"
import script from "./BHToolboxPanel.vue?vue&type=script&lang=js&"
export * from "./BHToolboxPanel.vue?vue&type=script&lang=js&"
import style0 from "./BHToolboxPanel.vue?vue&type=style&index=0&id=07b39221&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b39221",
  null
  
)

export default component.exports
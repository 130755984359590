<template>
    <div class="toolbox">
        <BHToolboxPanelTasks />

        <BHToolboxPanel
            heading="Jobs"
            icon="play"
            :is-collapsed="jobs.length === 0"
        >
            <p class="text-muted mb-2">
                Jobs ran for this process
            </p>

            <div
                v-for="(job, index) in filteredJobs"
                :id="`job-${index}`"
                :key="index"
            >
                <RouterLink
                    target="_blank"
                    :to="{name: 'admin.job.detail', params: {id: job.id}}"
                >
                    <strong>{{ job.id }}:</strong> {{ formatDate(job.createdAt) }}
                </RouterLink>
            </div>
        </BHToolboxPanel>
    </div>
</template>

<script>
    import filtersMixin from '@imt/vue-toolbox/src/mixins/filters';
    import {mapState} from 'vuex';

    import BHToolboxPanel from '@/components/builder/BHToolboxPanel.vue';
    import BHToolboxPanelTasks from '@/components/builder/BHToolboxPanelTasks.vue';

    export default {
        name: 'BHToolbox',
        components: {
            BHToolboxPanel,
            BHToolboxPanelTasks,
        },
        computed: {
            filteredJobs() {
                return this.jobs.filter(job => job.process?.id === this.version?.process?.id);
            },
            ...mapState('builder', [
                'jobs',
                'version',
            ]),
        },
        methods: {
            formatDate: filtersMixin.filters.date,
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .toolbox
        width: 20%
        height: 100%
        display: flex
        flex-direction: column

        @media (max-width: 900px)
            width: 50%

        @media (max-width: 500px)
            display: none

    .mode.dark
        .toolbox
            background-color: var(--kc-blue-800)
</style>

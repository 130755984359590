<template>
    <div class="workspace workspace-card">
        <div class="workspace-card-header">
            <div :class="['bar-top', {'unsaved': unsavedChanges}]" />

            <div class="row align-items-top px-2">
                <div class="col-12 col-md-6 col-lg-1">
                    <div class="data">
                        <h6 class="data-title">
                            ID
                        </h6>
                        <span class="data-content process-id text-break">
                            {{ version.process.id }}
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-auto">
                    <div class="data">
                        <h6 class="data-title">
                            Version
                        </h6>
                        <span class="data-content process-id">
                            {{ versionTitle }}
                            <span class="text-muted">
                                (ID: {{ version.id }})
                            </span>
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-2">
                    <div class="data">
                        <h6 class="data-title">
                            Title
                        </h6>
                        <span class="data-content version-title">
                            {{ version.title }}
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-2">
                    <div class="data">
                        <h6 class="data-title">
                            Policy System
                        </h6>
                        <span class="data-content version-policy-system">
                            {{ version.process.policySystemId === null ? 'All' : processPolicySystem }}
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <div class="data">
                        <h6 class="data-title">
                            Runs
                            <span
                                class="text-muted text-size-sm ml-1"
                            >
                                &mdash; This will be a future thing.
                            </span>
                        </h6>
                        <span
                            class="data-content"
                        >
                            Every Day at 9:00am CST
                        </span>
                    </div>
                </div>

                <div
                    v-if="!readOnly"
                    class="col-auto d-flex flex-row ml-lg-auto align-self-center align-items-center pb-2 pb-lg-0"
                >
                    <div class="data">
                        <h6 class="data-title d-lg-none">
                            Options
                        </h6>

                        <FontAwesomeIcon
                            id="processOptions"
                            icon="cog"
                            size="2x"
                            class="action options"
                        />

                        <BTooltip
                            target="processOptions"
                            triggers="hover"
                            placement="left"
                            title="Process Options"
                            custom-class="d-none d-lg-block"
                        />
                    </div>
                </div>
            </div>
        </div>

        <Draggable
            v-model="version.tasks"
            class="workspace-tasks accepts-drops"
            draggable=".task"
            data-cy="dropContainer"
            :group="{name: 'tasks', put: true}"
            @change="unsavedChanges = true;"
        >
            <BHWorkspaceTask
                v-for="(task, index) in version.tasks"
                :key="task.id"
                :task="task"
                :title="getTaskTitle(task)"
                :step="index + 1"
                :read-only="readOnly"
                :class="['task', {'d-none': filter.length && !testFilterMatch(getTaskTitle(task))}]"
                data-cy="droppedTask"
                @updated="updateTask($event, index)"
                @deleted="handleDeleteTask($event, index)"
            />

            <div
                v-if="!version.tasks.length"
                class="text-muted text-center my-4"
            >
                <FontAwesomeIcon
                    icon="empty-set"
                />
                No tasks for this process have been added yet.
            </div>
        </Draggable>

        <div
            v-if="version.tasks.length"
            class="workspace-card-footer"
        >
            <div class="ending-tail" />

            <div
                class="process-complete"
                @click="onCompleteOptionsExpanded = !onCompleteOptionsExpanded"
            >
                <FontAwesomeIcon
                    icon="check-circle"
                />
                Process Complete
            </div>

            <BCollapse
                id="processCompleteOptions"
                v-model="onCompleteOptionsExpanded"
                class="process-complete-options"
            >
                <div
                    class="container-fluid my-2 text-left"
                >
                    <div class="row">
                        <div class="col-12">
                            <h4
                                class="d-flex align-items-center"
                            >
                                On Complete Options
                            </h4>
                        </div>
                    </div>

                    <div class="row on-complete-options">
                        <div class="col-12 col-md-6">
                            <BFormGroup
                                label="On Success"
                                class="text-success"
                            >
                                <KCDropdown
                                    id="onSuccessProcess"
                                    v-model="onSuccess"
                                    allow-deselect
                                    :disabled="readOnly"
                                    :options="formattedProcesses"
                                    placeholder="select what to do..."
                                />
                            </BFormGroup>
                        </div>

                        <div class="col-12 col-md-6">
                            <BFormGroup
                                label="On Failure"
                                class="text-danger"
                            >
                                <KCDropdown
                                    id="onFailureProcess"
                                    v-model="onFailure"
                                    allow-deselect
                                    :disabled="readOnly"
                                    :options="formattedProcesses"
                                    placeholder="select what to do..."
                                />
                            </BFormGroup>
                        </div>
                    </div>
                </div>
            </BCollapse>
        </div>
    </div>
</template>

<script>
    import {KCDropdown} from '@imt/vue-kit-car';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import VersionMixin from '@imt/vue-versioning/src/mixins/versioning';
    import Draggable from 'vuedraggable';
    import {mapGetters, mapMutations, mapState} from 'vuex';

    import BHWorkspaceTask from '@/components/builder/BHWorkspaceTask.vue';

    export default {
        name: 'BHWorkspace',
        components: {
            Draggable,
            KCDropdown,
            BHWorkspaceTask,
        },
        mixins: [
            toastsMixin,
            VersionMixin,
        ],
        props: {
            readOnly: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                onCompleteOptionsExpanded: false,
                unsavedChanges: false,
            };
        },
        computed: {
            formattedProcesses() {
                let filtered = this.processes.filter(p => p.id !== this.version.process.id);

                return filtered.map(p => ({text: this.sortVersions(p)[0].title, value: parseInt(p.id)}));
            },
            onSuccess: {
                get() {
                    let selectedProcessOption = this.formattedProcesses.find(p => p.value === this.version.onSuccessId);

                    return selectedProcessOption ? [{text: selectedProcessOption.text, value: selectedProcessOption.value}] : [];
                },
                set(idSelection) {
                    const selectedValue = idSelection[0] ? idSelection[0].value : null;

                    if (selectedValue !== this.version.onSuccessId) {
                        this.unsavedChanges = true;
                        this.version.onSuccessId = selectedValue;
                    }
                }
            },
            onFailure: {
                get() {
                    let selectedProcessOption = this.formattedProcesses.find(p => p.value === this.version.onFailureId);

                    return selectedProcessOption ? [{text: selectedProcessOption.text, value: selectedProcessOption.value}] : [];
                },
                set(idSelection) {
                    const selectedValue = idSelection[0] ? idSelection[0].value : null;

                    if (selectedValue !== this.version.onFailureId) {
                        this.unsavedChanges = true;
                        this.version.onFailureId = selectedValue;
                    }
                }
            },
            processPolicySystem() {
                return this.formattedPolicySystems?.find(ps => ps.id === this.version.process.policySystemId?.toString())?.title;
            },
            versionTitle() {
                return (this.isDraft(this.version) === true ? 'Draft' : this.version.id);
            },
            ...mapState(['processes']),
            ...mapState('builder', [
                'deletedTasks',
                'filter',
                'version'
            ]),
            ...mapGetters('toolbox', [
                'formattedPolicySystems',
            ]),
        },
        methods: {
            getTaskTitle(task) {
                return task.function || task.title;
            },
            handleDeleteTask(task, index) {
                this.unsavedChanges = true;
                let version = {...this.version};
                version.tasks.splice(index, 1);
                this.SET_DATA({field: 'version', data: version});

                if (task.id) {
                    this.SET_DATA({field: 'deletedTasks', data: this.deletedTasks.concat(task)});
                }
            },
            testFilterMatch(value) {
                return RegExp(this.filter, 'gi').test(value);
            },
            updateTask(task, index) {
                let version = {...this.version};
                version.tasks[index] = task;
                this.unsavedChanges = true;
                this.SET_DATA({field: 'version', data: version});
            },
            ...mapMutations('builder', [
                'SET_DATA',
            ])
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    @keyframes wiggle
        0%
            transition: transform 300ms
            transform: rotate(0deg) translate(0, 0)
        25%
            transition: transform 300ms
            transform: rotate(-0.25deg) translate(1px, 0)
        50%
            transition: transform 300ms
            transform: rotate(0deg) translate(1px, 1px)
        75%
            transition: transform 300ms
            transform: rotate(0.25deg) translate(0, 1px)

    .workspace
        width: 100%
        display: flex
        flex-direction: column

        .accepts-drops
            min-height: 4rem

        &.workspace-card
            width: calc(100% - 2rem)
            display: flex
            flex-direction: column
            border-radius: var(--card-border-radius)
            background-color: var(--kc-gray-300)
            margin: 1rem

            .workspace-card-header
                width: 100%
                display: flex
                flex-direction: column
                border-radius: var(--card-border-radius) var(--card-border-radius) 0 0
                background-color: var(--kc-blue-500)
                color: var(--kc-gray-100)

                .bar-top
                    height: 0.25rem
                    background: var(--kc-blue-500)
                    width: 100%
                    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0
                    margin: 0

                    &.unsaved
                        background: var(--kc-yellow-500) !important

                .text-muted
                    color: var(--kc-gray-200) !important

                .action
                    &.options
                        color: var(--kc-gray-100)

                        &:hover
                            color: var(--kc-gray-300)
                            cursor: pointer

                .data
                    display: flex
                    flex-direction: column
                    padding: 0.5rem

                    .data-title,
                    .data-content
                        width: 100%

                    .data-title
                        font-weight: bold
                        font-size: 0.85rem

                    .data-content
                        font-weight: normal
                        font-size: 1rem

            .workspace-tasks
                padding: 1rem

                .workspace-task,
                .sortable-ghost
                    &:not(:last-child)
                        margin-bottom: 0.5rem

                .sortable-ghost
                    animation: wiggle infinite linear 300ms

            .workspace-card-footer
                text-align: center
                margin: -1rem 0 0.5rem 0
                color: var(--text-color)

                .process-complete
                    width: 100%
                    padding: 0.5rem

                    &:hover
                        cursor: pointer
                        color: var(--kc-gray-900)
                        background: var(--kc-gray-200)

                .process-complete-options
                    width: 100%
                    color: var(--kc-gray-900)

    .mode
        &.dark
            .workspace-tasks
                background-color: var(--kc-blue-800)

            .workspace
                &.workspace-card
                    background-color: var(--kc-blue-800)

                    .workspace-card-header
                        background-color: var(--kc-blue-600)

                        .bar-top
                            background: var(--kc-blue-600)

                            &.unsaved
                                background: var(--warning) !important

                    .workspace-card-footer
                        color: var(--kc-gray-200)
                        background-color: var(--kc-blue-800)
</style>

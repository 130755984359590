<template>
    <div class="process-builder">
        <BHToolbox v-if="version.publishedAt === null && canManage && !loading" />

        <div class="process-canvas">
            <BHWorkspaceToolbar
                :read-only="version.publishedAt !== null || !canManage"
                @revert="initialize"
                @save="save"
                @run-job="run"
                @workspace::tasks::filter="SET_DATA({field: 'filter', data: $event})"
            />

            <BHWorkspace
                v-if="!loading"
                ref="BHWorkspace"
                :read-only="version.publishedAt !== null || !canManage"
                data-cy="workspaceComponent"
            />
            <div
                v-else
                class="loading-indicator"
                data-cy="loadingIndicator"
            >
                <FontAwesomeIcon
                    far
                    icon="spinner"
                    pulse
                />
                loading...
            </div>
        </div>
    </div>
</template>

<script>
    import toastMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import toolboxUtils from '@imt/vue-toolbox/src/utils/index';
    import {mapActions, mapMutations, mapState} from 'vuex';

    import BHToolbox from '@/components/builder/BHToolbox.vue';
    import BHWorkspace from '@/components/builder/BHWorkspace.vue';
    import BHWorkspaceToolbar from '@/components/builder/BHWorkspaceToolbar.vue';
    import authMixin from '@/mixins/auth';

    export default {
        name: 'BHProcessBuilder',
        components: {
            BHToolbox,
            BHWorkspace,
            BHWorkspaceToolbar,
        },
        mixins: [
            authMixin,
            toastMixin,
        ],
        props: {
            mode: {
                type: String,
                required: false,
                default: 'light',
            },
        },
        data() {
            return {
                loading: true,
            };
        },
        computed: {
            ...mapState('builder', [
                'version',
            ]),
        },
        async created() {
            await this.initialize();
        },
        methods: {
            async initialize() {
                this.loading = true;
                try {
                    await this.fetchVersion(this.$route.params.id);
                    await Promise.all([
                        this.fetchProcesses('&filter[published_at]=None'),
                        this.fetchPolicySystems(),
                        this.fetchActions(),
                        this.fetchJobs(this.version.process.id)
                    ]);

                    this.loading = false;
                } catch (err) {
                    this.error('This process was not found or does not exist.', 'Process Not Found');
                    this.loading = false;
                    toolboxUtils.console.log(err);
                    await this.$router.push({name: 'admin.processes.list'});
                }
            },
            async run() {
                try {
                    let response = await this.runJob({
                        processId: this.version.process.id,
                        versionId: this.version.id
                    });

                    this.success(`Job started successfully. ID: ${response.id}`, 'Run Job');
                } catch (err) {
                    toolboxUtils.console.log(err);
                    this.error('Job failed!', 'Run Job');
                }
            },
            async save() {
                try {
                    await this.saveVersion();
                    this.success('Process saved successfully', 'Save Process');

                    if (this.$refs.BHWorkspace) {
                        this.$refs.BHWorkspace.unsavedChanges = false;
                    }
                } catch (err) {
                    toolboxUtils.console.log(err);
                    this.error('Could not save process');
                }
            },
            ...mapActions('toolbox', [
                'fetchPolicySystems',
            ]),
            ...mapActions([
                'fetchProcesses'
            ]),
            ...mapActions('builder', [
                'fetchActions',
                'fetchJobs',
                'fetchVersion',
                'saveVersion',
                'runJob',
            ]),
            ...mapMutations('builder', [
                'SET_DATA',
            ]),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .process-builder
        display: flex
        flex-direction: row
        width: 100%
        height: calc(100vh - 72px)

        .process-canvas
            padding: 0.5rem
            width: 100%
            overflow-y: auto

            .workspace-toolbar
                width: calc(100% + 1rem)
                margin: -0.5rem -0.5rem 0.5rem -0.5rem
                top: -0.5rem

    .loading-indicator
        font-size: 2rem
        width: 100%
        text-align: center
        padding: 3rem
        display: block
</style>

<style lang="sass">
    body .process-builder + .footer
        display: none !important
</style>

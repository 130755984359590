<template>
    <div class="workspace-toolbar container-fluid">
        <div class="row p-2">
            <div class="col-12 col-lg-auto px-0 mb-2 mb-lg-0">
                <BButton
                    v-if="canManage && !version.process.obsoleteAt"
                    v-shortkey="{windows: ['ctrl', 'shift', 'r'], mac: ['meta', 'shift', 'r']}"
                    variant="primary"
                    class="w-100"
                    data-cy="runJob"
                    @shortkey="$emit('run-job')"
                    @click="$emit('run-job')"
                >
                    <FontAwesomeIcon
                        icon="play"
                        class="mr-1"
                    />
                    Run Job with Test Data
                </BButton>
            </div>

            <div :class="['col-12', 'col-lg-auto', 'ml-lg-auto', 'px-0', 'mb-2', 'mb-lg-0', {'mr-lg-2': readOnly}]">
                <RouterLink
                    :to="{name: 'admin.processes.list'}"
                    class="btn btn-primary w-100"
                    data-cy="backToProcesses"
                >
                    <FontAwesomeIcon
                        icon="stream"
                        class="mr-1"
                    />
                    Back to All Processes
                </RouterLink>
            </div>

            <template v-if="!readOnly">
                <div
                    class="col-12 col-lg-auto px-0 px-lg-2 mb-2 mb-lg-0"
                    data-cy="revertProcess"
                >
                    <BButton
                        id="revertProcess"
                        :variant="saving ? 'warning' : 'primary'"
                        :disabled="saving"
                        class="w-100"
                    >
                        <FontAwesomeIcon
                            icon="redo-alt"
                            class="mr-1"
                        />
                        Revert
                    </BButton>

                    <KCPopConfirm
                        target="revertProcess"
                        placement="bottom"
                        title="Are you sure you want to revert?"
                        @confirmed="$emit('revert')"
                    >
                        <template #detail>
                            <span class="text-muted">This will clear any unsaved changes and revert the process and all tasks back to their original state before the last save.</span>
                        </template>
                    </KCPopConfirm>
                </div>

                <div
                    class="col-12 col-lg-auto px-0 pr-lg-2 mb-2 mb-lg-0"
                    data-cy="saveProcess"
                >
                    <BButton
                        v-shortkey="{windows: ['ctrl', 's'], mac: ['meta', 's']}"
                        :variant="saving ? 'warning' : 'primary'"
                        :disabled="saving"
                        class="w-100"
                        @shortkey="$emit('save')"
                        @click="$emit('save')"
                    >
                        <FontAwesomeIcon
                            icon="save"
                            class="mr-1"
                        />
                        Save
                    </BButton>
                </div>
            </template>

            <div class="col-12 col-lg-3 px-0">
                <BInputGroup>
                    <template #prepend>
                        <BInputGroupText
                            class="clickable"
                            @click="$refs.taskFilter.focus()"
                        >
                            <FontAwesomeIcon icon="filter" />
                        </BInputGroupText>
                    </template>

                    <BInput
                        ref="taskFilter"
                        v-model="filter"
                        placeholder="filter tasks by title..."
                        data-cy="filterTasks"
                    />
                </BInputGroup>
            </div>
        </div>
    </div>
</template>

<script>
    import {KCPopConfirm} from '@imt/vue-kit-car';
    import {mapState} from 'vuex';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'BHWorkspaceToolbar',
        components: {
            KCPopConfirm,
        },
        mixins: [
            authMixin,
        ],
        props: {
            readOnly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tasks: [],
                filter: '',
            };
        },
        computed: {
            ...mapState('builder', [
                'version',
                'saving',
            ]),
        },
        watch: {
            filter(newValue) {
                this.performFilter(newValue);
            }
        },
        methods: {
            performFilter(value) {
                this.$emit(`workspace::tasks::filter`, value);
            },
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .workspace-toolbar
        background-color: var(--kc-gray-400)
        position: sticky
        top: 0
        z-index: 1010

        .clickable
            &:hover
                cursor: pointer

        .input-group-text
            &.clickable
                &:hover
                    background-color: var(--kc-gray-200)

    .mode
        &.dark
            .workspace-toolbar
                background-color: var(--kc-blue-800)

                .input-group-text
                    &.clickable
                        &:hover
                            background-color: var(--kc-gray-400)
</style>

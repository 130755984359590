<template>
    <div
        class="toolbox-card"
        data-cy="draggableTask"
    >
        <div class="bar-top" />

        <div class="toolbox-card-header">
            {{ heading }}
        </div>

        <div class="toolbox-card-sub-header">
            {{ description }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BHToolboxCard',
        props: {
            heading: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style
    lang="sass"
>
    .toolbox-card
        width: 100%
        display: flex
        flex-direction: column
        border-radius: 0.25rem
        background-color: var(--kc-gray-200)
        padding-bottom: 0.25rem

        &:hover
            cursor: grab

        &.sortable-ghost
            &:hover
                cursor: grabbing

        .bar-top
            height: 0.25rem
            background: var(--kc-blue-500)
            width: 100%
            border-radius: 0.25rem 0.25rem 0 0
            margin: 0 0 0.25rem 0

        .toolbox-card-header
            width: 100%
            padding: 0 0.5rem
            margin-bottom: 0.25rem
            font-weight: bold
            word-break: break-word

        .toolbox-card-sub-header
            width: 100%
            padding: 0 0.5rem
            word-break: break-word

    .mode.dark
        .toolbox-card
            background-color: var(--kc-blue-700)
            color: var(--text-color)

            .bar-top
                background: var(--kc-blue-600)
</style>

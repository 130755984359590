<template>
    <BHToolboxPanel
        heading="Tasks"
        icon="tasks-alt"
        :is-collapsed="isCollapsed"
    >
        <div class="sticky-top toolbox-panel-toolbar">
            <BInputGroup
                v-if="tasks.length || filter.length"
            >
                <template #prepend>
                    <BInputGroupText
                        class="clickable"
                        @click="$refs.taskFilter.focus()"
                    >
                        <FontAwesomeIcon
                            icon="filter"
                        />
                    </BInputGroupText>
                </template>

                <BInput
                    ref="taskFilter"
                    v-model="filter"
                    placeholder="filter available tasks..."
                    autocomplete="off"
                    data-cy="filterActions"
                />
            </BInputGroup>
        </div>

        <Draggable
            v-model="tasks"
            :group="{name: 'tasks', pull: 'clone', put: false}"
            :sort="false"
        >
            <BHToolboxCard
                v-for="task in tasks"
                :key="task.id"
                :heading="task.title"
                :description="task.description"
            />
        </Draggable>

        <div
            v-if="!tasks.length && filter.length"
            class="text-muted w-100 text-center"
        >
            No tasks match what you've filtered.
        </div>
        <div
            v-else-if="!tasks.length"
            class="text-muted w-100 text-center"
        >
            No tasks found.
        </div>
    </BHToolboxPanel>
</template>

<script>
    import Draggable from 'vuedraggable';
    import {mapGetters} from 'vuex';

    import BHToolboxCard from '@/components/builder/BHToolboxCard.vue';
    import BHToolboxPanel from '@/components/builder/BHToolboxPanel.vue';

    export default {
        name: 'BHToolboxPanelTasks',
        components: {
            Draggable,
            BHToolboxCard,
            BHToolboxPanel,
        },
        props: {
            isCollapsed: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data() {
            return {
                filter: '',
            };
        },
        computed: {
            tasks: {
                get() {
                    return this.filter.length ? this.actions.filter(t => RegExp(this.filter, 'gi').test(`${t.title} ${t.description}`)) : this.actions;
                },
                set() {
                    return false;
                }
            },
            ...mapGetters('builder', [
                'actions',
            ]),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .toolbox-card
        + .toolbox-card
            margin-top: 0.5rem

    .toolbox-panel-body
        .toolbox-panel-toolbar
            background-color: var(--kc-gray-300)
            margin: -0.25rem -0.25rem 0.25rem -0.25rem
            padding: 0.25rem
            top: -0.25rem

    .mode.dark
        .toolbox-panel-body
            .toolbox-panel-toolbar
                background-color: var(--kc-blue-800)

            .text-muted
                color: var(--kc-gray-400) !important
</style>

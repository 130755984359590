<template>
    <div :class="['toolbox-panel', {'expanded': expanded}]">
        <div
            class="toolbox-panel-heading"
            data-cy="toolboxPanelHeading"
            @click="expanded = !expanded"
        >
            <FontAwesomeIcon
                :icon="icon"
            />

            <span class="ml-2">{{ heading }}</span>
        </div>

        <BCollapse
            :id="`${camelCase(heading)}Collapse`"
            :ref="`${camelCase(heading)}Collapse`"
            v-model="expanded"
            class="toolbox-panel-body"
            @show="$emit('show', $event)"
            @shown="$emit('shown', $event)"
            @hide="$emit('hide', $event)"
            @hidden="$emit('hidden', $event)"
        >
            <slot />
        </BCollapse>
    </div>
</template>

<script>
    import {camelCase} from 'lodash';

    export default {
        name: 'BHToolboxPanel',
        props: {
            heading: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                required: false,
                default: 'square'
            },
            isCollapsed: {
                type: Boolean,
                required: false,
                default: true
            },
        },
        data() {
            return {
                expanded: false,
            };
        },
        created() {
            this.expanded = !this.isCollapsed;
        },
        methods: {
            camelCase,
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .toolbox-panel
        width: 100%
        overflow: hidden
        flex-grow: 0
        transition: flex-grow 300ms
        display: flex
        flex-direction: column

        &.expanded
            flex: 1

        .toolbox-panel-heading
            align-items: center
            background-color: var(--kc-gray-400)
            border-bottom: 1px solid var(--kc-gray-400)
            display: flex
            flex-shrink: 0
            height: 58px
            padding: 0.5rem

            &:hover
                cursor: pointer
                background-color: var(--kc-gray-200)

        .toolbox-panel-body
            background-color: var(--kc-gray-300)
            flex-grow: 1
            max-height: 100%
            overflow-y: auto
            padding: 0.25rem

    .mode.dark
        .toolbox-panel
            background-color: var(--kc-blue-700)

            .toolbox-panel-body
                background-color: var(--kc-blue-800)

            .toolbox-panel-heading
                background-color: var(--kc-blue-800)
                border-bottom-color: var(--kc-blue-700)

                &:hover
                    background-color: var(--kc-blue-700)
</style>
